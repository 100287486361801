define("ember-concurrency/-private/external/scheduler/policies/execution-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeCancelState = _exports.QUEUED = _exports.STARTED = _exports.TYPE_QUEUED = _exports.TYPE_STARTED = _exports.TYPE_CANCELLED = void 0;
  const TYPE_CANCELLED = "CANCELLED";
  _exports.TYPE_CANCELLED = TYPE_CANCELLED;
  const TYPE_STARTED = "STARTED";
  _exports.TYPE_STARTED = TYPE_STARTED;
  const TYPE_QUEUED = "QUEUED";
  _exports.TYPE_QUEUED = TYPE_QUEUED;
  const STARTED = {
    type: TYPE_STARTED
  };
  _exports.STARTED = STARTED;
  const QUEUED = {
    type: TYPE_QUEUED
  };
  _exports.QUEUED = QUEUED;

  const makeCancelState = reason => ({
    type: TYPE_CANCELLED,
    reason
  });

  _exports.makeCancelState = makeCancelState;
});