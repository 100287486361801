define("ember-concurrency/-encapsulated-task", ["exports", "ember-concurrency/-task-instance"], function (_exports, _taskInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _taskInstance.default.extend({
    _makeIterator() {
      let perform = this.perform;
      (false && !(typeof perform === 'function') && Ember.assert("The object passed to `task()` must define a `perform` generator function, e.g. `perform: function * (a,b,c) {...}`, or better yet `*perform(a,b,c) {...}`", typeof perform === 'function'));
      return perform.apply(this, this.args);
    },

    perform: null
  });

  _exports.default = _default;
});