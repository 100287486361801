define("ember-could-get-used-to-this/initializers/usable-function-manager", ["exports", "ember-could-get-used-to-this/-private/functions"], function (_exports, _functions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {// application.inject('route', 'foo', 'service:foo');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});