define("ember-macro-helpers/curried-computed", ["exports", "ember-macro-helpers/-build-computed", "ember-macro-helpers/computed"], function (_exports, _buildComputed, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildComputed.buildCurriedComputed)(_computed.default);

  _exports.default = _default;
});