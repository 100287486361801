define("ember-statecharts/usables/use-machine", ["exports", "xstate", "ember-usable"], function (_exports, _xstate, _emberUsable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = useMachine;
  _exports.MachineInterpreterManager = _exports.InterpreterService = _exports.ARGS_STATE_CHANGE_WARNING = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ARGS_STATE_CHANGE_WARNING = 'A change to passed `args` or a local state change triggered an update to a `useMachine`-usable. You can send a dedicated event to the machine or restart it so this is handled. This is done via the `.update`-hook of the `useMachine`-usable.';
  _exports.ARGS_STATE_CHANGE_WARNING = ARGS_STATE_CHANGE_WARNING;
  let InterpreterService = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class InterpreterService {
    constructor(machine, interpreterOptions, onTransition) {
      _initializerDefineProperty(this, "service", _descriptor, this);

      _initializerDefineProperty(this, "_state", _descriptor2, this);

      _defineProperty(this, "machine", void 0);

      _defineProperty(this, "interpreterOptions", void 0);

      _defineProperty(this, "onTransition", undefined);

      this.machine = machine;
      this.interpreterOptions = interpreterOptions || {};
      this.onTransition = onTransition;
    }

    get state() {
      return {
        state: this._state,
        send: this.service.send,
        service: this.service
      };
    }

    setup(setupOptions = {}) {
      const {
        state
      } = this.interpreterOptions;
      this.service = (0, _xstate.interpret)(this.machine, {
        devTools: false
        /* DEBUG */
        ,
        ...this.interpreterOptions,
        clock: {
          setTimeout(fn, ms) {
            return Ember.run.later.call(null, fn, ms);
          },

          clearTimeout(timer) {
            return Ember.run.cancel.call(null, timer);
          }

        }
      }).onTransition(state => {
        this._state = state;
      });

      if (this.onTransition) {
        this.service.onTransition(this.onTransition);
      }

      this.service.start(setupOptions.initialState || state);
    }

    teardown() {
      this.service.stop();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_state", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.InterpreterService = InterpreterService;

  class MachineInterpreterManager {
    createUsable(context, {
      machine,
      interpreterOptions,
      _onTransition
    }) {
      const owner = Ember.getOwner(context);
      let onTransition;

      if (_onTransition) {
        onTransition = _onTransition.bind(context);
      }

      const interpreter = new InterpreterService(machine, interpreterOptions, onTransition);
      Ember.setOwner(interpreter, owner);
      return {
        interpreter
      };
    }

    getState({
      interpreter
    }) {
      return interpreter.state;
    }

    setupUsable({
      interpreter,
      setupOptions
    }) {
      interpreter.setup(setupOptions);
    }

    updateUsable(bucket, configurableMachineDefinition) {
      const {
        interpreter
      } = bucket;
      const {
        args,
        _update
      } = configurableMachineDefinition;

      if (_update) {
        const {
          machine,
          context,
          config
        } = args;

        _update({
          machine,
          context,
          config,
          send: interpreter.service.send,
          restart: this.restartUsable.bind(this, bucket, configurableMachineDefinition)
        });
      } else {
        (false && Ember.warn(ARGS_STATE_CHANGE_WARNING, false, {
          id: 'statecharts.use-machine.args-state-change'
        }));
      }
    }

    teardownUsable({
      interpreter
    }) {
      interpreter.teardown();
    }

    restartUsable(bucket, configurableMachineDefinition, state) {
      this.teardownUsable(bucket);
      bucket.interpreter = this.createUsable(bucket, configurableMachineDefinition).interpreter;
      bucket.setupOptions = {
        initialState: state
      };
      this.setupUsable(bucket);
    }

  }

  _exports.MachineInterpreterManager = MachineInterpreterManager;

  const createMachineInterpreterManager = () => {
    return new MachineInterpreterManager();
  };

  const MANAGED_INTERPRETER = {};
  (0, _emberUsable.setUsableManager)(MANAGED_INTERPRETER, createMachineInterpreterManager);

  function useMachine(machine, interpreterOptions = {}) {
    const configurableMachineDefinition = Object.create(MANAGED_INTERPRETER);
    machine = machine instanceof _xstate.StateNode ? machine : (0, _xstate.createMachine)(machine);
    configurableMachineDefinition.machine = machine;
    configurableMachineDefinition.interpreterOptions = interpreterOptions;
    configurableMachineDefinition.args = {
      machine,
      interpreterOptions
    };

    configurableMachineDefinition.update = function (fn) {
      configurableMachineDefinition._update = fn;
      return configurableMachineDefinition;
    };

    configurableMachineDefinition.onTransition = function (fn) {
      configurableMachineDefinition._onTransition = fn;
      return configurableMachineDefinition;
    };

    configurableMachineDefinition.withConfig = function (config) {
      configurableMachineDefinition.machine = configurableMachineDefinition.machine.withConfig(config);
      configurableMachineDefinition.args.machine = configurableMachineDefinition.machine;
      configurableMachineDefinition.args.config = config;
      return configurableMachineDefinition;
    };

    configurableMachineDefinition.withContext = function (context) {
      configurableMachineDefinition.machine = configurableMachineDefinition.machine.withContext(context);
      configurableMachineDefinition.args.machine = configurableMachineDefinition.machine;
      configurableMachineDefinition.args.context = context;
      return configurableMachineDefinition;
    };

    return configurableMachineDefinition;
  }
});