define("ember-table/-private/meta-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOrCreate = getOrCreate;

  function getOrCreate(obj, cache, Class) {
    if (cache.has(obj) === false) {
      cache.set(obj, Class.create ? Class.create() : new Class());
    }

    return cache.get(obj);
  }
});