define("ember-notifier/components/ember-notifier-notification/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YeWIGtIH",
    "block": "[[[10,1],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"i\"],[15,0,[30,0,[\"icon\"]]],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-notifier/components/ember-notifier-notification/icon/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});