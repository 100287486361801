define('ember-sortable/utils/transition-duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = transitionDuration;
  const $ = Ember.$;


  /**
    @private
    @method transitionDuration
    @param {HTMLElement} el
    @return {Number}
  */
  function transitionDuration(el) {
    $(el).height(); // force re-flow

    let value = $(el).css('transition');
    if (value === null || value === undefined) return 0;
    let match = value.match(/(all|transform) ([\d\.]+)([ms]*)/);

    if (match) {
      let magnitude = parseFloat(match[2]);
      let unit = match[3];

      if (unit === 's') {
        magnitude *= 1000;
      }

      return magnitude;
    } else {
      return 0;
    }
  }
});