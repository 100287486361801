define("ember-statecharts/utils/statechart", ["exports", "xstate"], function (_exports, _xstate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Statechart {
    constructor(config, options, initialContext) {
      const machine = (0, _xstate.Machine)(config, options, initialContext);
      this.service = (0, _xstate.interpret)(machine, {
        clock: {
          setTimeout: (fn, ms) => {
            return Ember.run.later.call(null, fn, ms);
          },
          clearTimeout: timer => {
            return Ember.run.cancel.call(null, timer);
          }
        }
      }).onTransition(state => {
        Ember.set(this, 'currentState', state);
      }).start();
    }

    send(event, data = {}) {
      if (arguments.length === 1) {
        this._sendEventObject(event);
      } else {
        const {
          type,
          ...eventData
        } = data;

        if (type) {
          (false && Ember.warn(`You passed property \`type\` as part of the data you sent with the event \`${event}\` . This is not supported - \`${event}\` will be used as event name.`, false, {
            id: 'statecharts.event-object.no-override-type'
          }));
        }

        const eventObject = { ...eventData,
          type: event
        };

        this._sendEventObject(eventObject);
      }
    }

    _sendEventObject(eventObject) {
      this.service.send(eventObject);
    }

  }

  _exports.default = Statechart;
});