define('ember-validators/index', ['exports', 'ember-require-module'], function (exports, _emberRequireModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validate = validate;
  function validate(type, ...args) {
    let validator = (0, _emberRequireModule.default)(`ember-validators/${type}`);

    (false && !(Ember.isPresent(validator)) && Ember.assert(`Validator not found of type: ${type}.`, Ember.isPresent(validator)));


    return validator(...args);
  }
});