define("ember-in-viewport/utils/find-elem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    let elem;

    if (context.nodeType === Node.ELEMENT_NODE || context.nodeType === Node.DOCUMENT_NODE || context instanceof Window) {
      elem = context;
    } else {
      elem = document.querySelector(context);
    }

    return elem;
  }
});