define("ember-stargate/components/portal", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.renderInPlace}}
    {{yield}}
  {{else if this.target}}
    {{#if this.target.multiple}}
      {{#in-element this.target.element insertBefore=null}}
        {{yield}}
      {{/in-element}}
    {{else}}
      {{#in-element this.target.element}}
        {{yield}}
      {{/in-element}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "qOmMchRA",
    "block": "[[[41,[30,0,[\"renderInPlace\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"target\"]],[[[41,[30,0,[\"target\",\"multiple\"]],[[[40,[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"target\",\"element\"]],null]],[]],[[[40,[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:1%\",[30,0,[\"target\",\"element\"]]]],[]]]],[]],null]],[]]]],[\"&default\"],false,[\"if\",\"yield\",\"in-element\"]]",
    "moduleName": "ember-stargate/components/portal.hbs",
    "isStrictMode": false
  });

  let PortalComponent = (_dec = Ember.inject.service('-portal'), (_class = class PortalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "portalService", _descriptor, this);

      Ember.run.next(() => this.portalService.registerPortal(this.args.target));
    }

    get target() {
      return this.args.target && this.portalService.getTarget(this.args.target);
    }

    get renderInPlace() {
      return this.args.renderInPlace === true || !this.target && this.args.fallback === 'inplace';
    }

    willDestroy() {
      super.willDestroy();
      Ember.run.next(() => this.portalService.unregisterPortal(this.args.target));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "portalService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PortalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PortalComponent);
});