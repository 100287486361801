define("ember-keyboard/mixins/activate-keyboard-on-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activateKeyboardWhenFocused: Ember.on('click', 'focusIn', function () {
      Ember.set(this, 'keyboardActivated', true);
    }),
    deactivateKeyboardWhenFocusOut: Ember.on('focusOut', function () {
      Ember.set(this, 'keyboardActivated', false);
    })
  });

  _exports.default = _default;
});