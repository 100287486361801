define("ember-animated-tools/templates/components/time-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ax3NqSpp",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"Speed \"],[1,[28,[35,0],[[33,1]],null]],[1,\"%\"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[14,0,\"eat-time-control--tick-marks\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"      \"],[11,0],[16,5,[30,1,[\"position\"]]],[4,[38,5],[[30,0],[33,6],[30,1]],null],[12],[1,\"\\n        \"],[10,1],[12],[1,[30,1,[\"text\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"input\"],[14,0,\"eat-time-control--slider\"],[15,2,[36,7]],[14,\"min\",\"0\"],[14,\"max\",\"200\"],[14,\"step\",\"1\"],[15,\"oninput\",[28,[37,5],[[30,0],[33,8]],null]],[14,4,\"range\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"tickMark\"],false,[\"-eat-rounded\",\"speedPercent\",\"each\",\"-track-array\",\"tickMarks\",\"action\",\"tickMarkChosen\",\"logSpeed\",\"updateLogSpeed\"]]",
    "moduleName": "ember-animated-tools/templates/components/time-control.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});