define("ember-modal-dialog/initializers/add-modals-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  let hasDOM = typeof document !== 'undefined';

  function appendContainerElement(rootElementOrId, id) {
    if (!hasDOM) {
      return;
    }

    if (document.getElementById(id)) {
      return;
    }

    let rootEl = rootElementOrId.appendChild ? rootElementOrId : document.querySelector(rootElementOrId);
    let modalContainerEl = document.createElement('div');
    modalContainerEl.id = id;
    rootEl.appendChild(modalContainerEl);
  }

  function _default(AppOrEngine) {
    var _ref, _AppOrEngine$emberMod;

    let App;

    if (AppOrEngine instanceof Ember.Application) {
      App = AppOrEngine;
    } else if (AppOrEngine instanceof Ember.Engine) {
      // As there is only a single `Router` across the whole app, which is owned
      // by the root `Application`, this reliably finds the root `Application`
      // from an`Engine`.
      // eslint-disable-next-line ember/no-private-routing-service
      App = Ember.getOwner(Ember.getOwner(AppOrEngine).lookup('router:main'));
    } else {
      (false && !(false) && Ember.assert(`Could not find the root Application for '${AppOrEngine}'.`));
    }

    let emberModalDialog = (_ref = (_AppOrEngine$emberMod = AppOrEngine.emberModalDialog) !== null && _AppOrEngine$emberMod !== void 0 ? _AppOrEngine$emberMod : App.emberModalDialog) !== null && _ref !== void 0 ? _ref : {};
    let modalContainerElId = emberModalDialog.modalRootElementId || 'modal-overlays';
    AppOrEngine.register('config:modals-container-id', Ember.testing ? 'ember-testing' : modalContainerElId, {
      instantiate: false
    });
    AppOrEngine.inject('service:modal-dialog', 'destinationElementId', 'config:modals-container-id');
    appendContainerElement(App.rootElement, modalContainerElId);
  }
});