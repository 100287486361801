define("ember-usable/-private/destroyable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerDestroyable = registerDestroyable;
  _exports.destroy = destroy;
  let DESTROYABLES = new WeakMap();

  function registerDestroyable(context, destructor) {
    let destroyables = DESTROYABLES.get(context);

    if (destroyables === undefined) {
      destroyables = [];

      if (typeof context.willDestroy === 'function') {
        let oldWillDestroy = context.willDestroy;

        context.willDestroy = function () {
          oldWillDestroy.call(this);
          destroy(this);
        };
      }

      DESTROYABLES.set(context, destroyables);
    }

    destroyables.push(destructor);
  }

  function destroy(context) {
    let destroyables = DESTROYABLES.get(context);

    if (destroyables !== undefined) {
      for (let destroyable of destroyables) {
        destroyable();
      }

      DESTROYABLES.delete(context);
    }
  }
});