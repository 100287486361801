define("ember-cli-clipboard/templates/components/copy-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l0+5fOt1",
    "block": "[[[11,\"button\"],[16,\"aria-label\",[30,1]],[16,0,[29,[\"copy-btn \",[30,2],\" \",[30,3]]]],[16,\"disabled\",[30,4]],[16,\"title\",[30,5]],[16,4,[52,[30,6],[30,6],\"button\"]],[17,7],[16,\"data-clipboard-text\",[30,8]],[16,\"data-clipboard-target\",[30,9]],[16,\"data-clipboard-action\",[30,10]],[4,[38,1],[[30,0,[\"setupElement\"]]],null],[4,[38,1],[[30,0,[\"registerClipboard\"]]],null],[4,[38,2],[[30,0,[\"registerClipboard\"]]],null],[4,[38,3],[[30,0,[\"destroyClipboard\"]]],null],[12],[1,\"\\n  \"],[18,11,null],[1,\"\\n\"],[13]],[\"@aria-label\",\"@class\",\"@classNames\",\"@disabled\",\"@title\",\"@buttonType\",\"&attrs\",\"@clipboardText\",\"@clipboardTarget\",\"@clipboardAction\",\"&default\"],false,[\"if\",\"did-insert\",\"did-update\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-cli-clipboard/templates/components/copy-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});