define("ember-keyboard/initializers/ember-keyboard-first-responder-inputs", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.TextField.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
    Ember.TextArea.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
  }

  var _default = {
    name: 'ember-keyboard-first-responder-inputs',
    initialize: initialize
  };
  _exports.default = _default;
});