define("ember-keyboard/mixins/activate-keyboard-on-init", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activateKeyboardWhenStarted: Ember.on('init', function () {
      Ember.set(this, 'keyboardActivated', true);
    })
  });

  _exports.default = _default;
});