define("ember-concurrency/-private/taskable-mixin", ["exports", "ember-concurrency/-private/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TASKABLE_MIXIN = void 0;
  const TASKABLE_MIXIN = {
    _performCount: 0,

    setState(state) {
      this._performCount = this._performCount + (state.numPerformedInc || 0);
      let isRunning = state.numRunning > 0;
      let isQueued = state.numQueued > 0;
      let derivedState = Object.assign({}, state, {
        performCount: this._performCount,
        isRunning,
        isQueued,
        isIdle: !isRunning && !isQueued,
        state: isRunning ? "running" : "idle"
      });
      (0, _utils.assignProperties)(this, derivedState);
    },

    onState(state, task) {
      if (task.onStateCallback) {
        task.onStateCallback(state, task);
      }
    }

  };
  _exports.TASKABLE_MIXIN = TASKABLE_MIXIN;
});