define("ember-usable/-private/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.memoComputation = memoComputation;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Runner = (_dec = Ember.computed('watcher'), _dec2 = Ember.computed('watcher'), (_class = class Runner {
    constructor() {
      _defineProperty(this, "_validFlag", false);
    }

    get runner() {
      return this.watcher;
    }

    get _isValid() {
      return this._validFlag = !this._validFlag;
    }

    isValid() {
      let pre = this._validFlag;
      this._isValid;
      return this._validFlag === pre;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "runner", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "runner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_isValid", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_isValid"), _class.prototype)), _class));

  function memoComputation(fn) {
    let obj = new Runner();
    Ember.defineProperty(obj, 'watcher', Ember._dependentKeyCompat({
      get: fn
    }));
    return () => obj.runner;
  }
});