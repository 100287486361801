define("ember-keyboard/mixins/ember-keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(Ember.Evented, {
    keyboardPriority: 0,
    keyboard: Ember.inject.service(),

    init(...args) {
      Ember.get(this, 'keyboard').register(this);
      return this._super(...args);
    },

    willDestroyElement(...args) {
      this._super(...args);

      Ember.get(this, 'keyboard').unregister(this);
    },

    willDestroy(...args) {
      this._super(...args);

      Ember.get(this, 'keyboard').unregister(this);
    }

  });

  _exports.default = _default;
});