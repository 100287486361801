define('ember-validators/date', ['exports', 'ember-validators/utils/validation-error', 'ember-require-module'], function (exports, _validationError, _emberRequireModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDate;
  exports.parseDate = parseDate;


  const moment = (0, _emberRequireModule.default)('moment');

  /**
   * @class Date
   * @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {String} options.before The specified date must be before this date
   * @param {String} options.onOrBefore The specified date must be on or before this date
   * @param {String} options.after The specified date must be after this date
   * @param {String} options.onOrAfter The specified date must be on or after this date
   * @param {String} options.precision Limit the comparison check to a specific granularity.
   *                                   Possible Options: [`year`, `month`, `week`, `day`, `hour`, `minute`, `second`].
   * @param {String} options.format Input value date format
   * @param {String} options.errorFormat Error output date format. Defaults to `MMM Do, YYYY`
   * @param {Object} model
   * @param {String} attribute
   */
  function validateDate(value, options) {
    if (!moment) {
      throw new Error('MomentJS is required to use the Date validator.');
    }

    let errorFormat = Ember.get(options, 'errorFormat') || 'MMM Do, YYYY';

    var _Ember$getProperties = Ember.getProperties(options, ['format', 'precision', 'allowBlank']);

    let format = _Ember$getProperties.format,
        precision = _Ember$getProperties.precision,
        allowBlank = _Ember$getProperties.allowBlank;

    var _Ember$getProperties2 = Ember.getProperties(options, ['before', 'onOrBefore', 'after', 'onOrAfter']);

    let before = _Ember$getProperties2.before,
        onOrBefore = _Ember$getProperties2.onOrBefore,
        after = _Ember$getProperties2.after,
        onOrAfter = _Ember$getProperties2.onOrAfter;

    let date;

    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    if (format) {
      date = parseDate(value, format, true);

      // Check to see if the passed date is actually a valid date.
      // This can be done by disabling the strict parsing
      const isActualDate = parseDate(value, format).isValid();

      if (!isActualDate) {
        return (0, _validationError.default)('date', value, options);
      } else if (!date.isValid()) {
        return (0, _validationError.default)('wrongDateFormat', value, options);
      }
    } else {
      date = parseDate(value);

      if (!date.isValid()) {
        return (0, _validationError.default)('date', value, options);
      }
    }

    if (before) {
      before = parseDate(before, format);

      if (!date.isBefore(before, precision)) {
        Ember.set(options, 'before', before.format(errorFormat));
        return (0, _validationError.default)('before', value, options);
      }
    }

    if (onOrBefore) {
      onOrBefore = parseDate(onOrBefore, format);

      if (!date.isSameOrBefore(onOrBefore, precision)) {
        Ember.set(options, 'onOrBefore', onOrBefore.format(errorFormat));
        return (0, _validationError.default)('onOrBefore', value, options);
      }
    }

    if (after) {
      after = parseDate(after, format);

      if (!date.isAfter(after, precision)) {
        Ember.set(options, 'after', after.format(errorFormat));
        return (0, _validationError.default)('after', value, options);
      }
    }

    if (onOrAfter) {
      onOrAfter = parseDate(onOrAfter, format);

      if (!date.isSameOrAfter(onOrAfter, precision)) {
        Ember.set(options, 'onOrAfter', onOrAfter.format(errorFormat));
        return (0, _validationError.default)('onOrAfter', value, options);
      }
    }

    return true;
  }

  function parseDate(date, format, useStrict = false) {
    if (date === 'now' || Ember.isEmpty(date)) {
      return moment();
    }

    return Ember.isNone(format) ? moment(new Date(date)) : moment(date, format, useStrict);
  }
});