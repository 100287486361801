define("ember-stargate/services/-portal", ["exports", "tracked-maps-and-sets"], function (_exports, _trackedMapsAndSets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classPrivateFieldGet(receiver, privateMap) { var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "get"); return _classApplyDescriptorGet(receiver, descriptor); }

  function _classExtractFieldDescriptor(receiver, privateMap, action) { if (!privateMap.has(receiver)) { throw new TypeError("attempted to " + action + " private field on non-instance"); } return privateMap.get(receiver); }

  function _classApplyDescriptorGet(receiver, descriptor) { if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TargetTracker {
    constructor(name, element, options) {
      var _options$multiple;

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "element", void 0);

      _defineProperty(this, "multiple", void 0);

      _defineProperty(this, "onChange", void 0);

      this.name = name;
      this.element = element;
      this.multiple = (_options$multiple = options.multiple) !== null && _options$multiple !== void 0 ? _options$multiple : false;
      this.onChange = options.onChange;
    }

  }

  var _targets = new WeakMap();

  var _portalCount = new WeakMap();

  class PortalService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _targets.set(this, {
        writable: true,
        value: new _trackedMapsAndSets.TrackedMap()
      });

      _portalCount.set(this, {
        writable: true,
        value: new _trackedMapsAndSets.TrackedMap()
      });
    }

    getTarget(name) {
      return _classPrivateFieldGet(this, _targets).get(name);
    }

    getPortalCount(name) {
      var _classPrivateFieldGet2;

      return (_classPrivateFieldGet2 = _classPrivateFieldGet(this, _portalCount).get(name)) !== null && _classPrivateFieldGet2 !== void 0 ? _classPrivateFieldGet2 : 0;
    }

    registerTarget(name, element, options) {
      (false && !(!_classPrivateFieldGet(this, _targets).has(name)) && Ember.assert(`Portal target with name ${name} already exists`, !_classPrivateFieldGet(this, _targets).has(name)));

      _classPrivateFieldGet(this, _targets).set(name, new TargetTracker(name, element, options));
    }

    unregisterTarget(name) {
      _classPrivateFieldGet(this, _targets).delete(name);
    }

    registerPortal(name) {
      var _classPrivateFieldGet3;

      const count = ((_classPrivateFieldGet3 = _classPrivateFieldGet(this, _portalCount).get(name)) !== null && _classPrivateFieldGet3 !== void 0 ? _classPrivateFieldGet3 : 0) + 1;

      _classPrivateFieldGet(this, _portalCount).set(name, count);

      const target = this.getTarget(name);

      if (target && target.onChange) {
        target.onChange(count);
      }
    }

    unregisterPortal(name) {
      var _classPrivateFieldGet4;

      let count = (_classPrivateFieldGet4 = _classPrivateFieldGet(this, _portalCount).get(name)) !== null && _classPrivateFieldGet4 !== void 0 ? _classPrivateFieldGet4 : 0;
      (false && !(count > 0) && Ember.assert(`Trying to unregister a portal that hasn't been registered before`, count > 0));
      count--;

      _classPrivateFieldGet(this, _portalCount).set(name, count);

      const target = this.getTarget(name);

      if (target && target.onChange) {
        target.onChange(count);
      }
    }

  }

  _exports.default = PortalService;
});