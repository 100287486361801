define("ember-notifier/components/ember-notifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pGkbSNgl",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[48,[30,2]],[[[6,[39,5],null,[[\"class\",\"notification\",\"close\",\"iconComponent\",\"contentComponent\",\"closeComponent\"],[[28,[37,6],[[33,7]],null],[30,1],[28,[37,8],[[30,0],\"remove\",[30,1]],null],[28,[37,6],[[33,9]],null],[28,[37,6],[[33,10]],null],[28,[37,6],[[33,11]],null]]],[[\"default\"],[[[[1,\"      \"],[18,2,[[30,1],[28,[37,8],[[30,0],\"remove\",[30,1]],null]]],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"    \"],[1,[28,[35,5],null,[[\"class\",\"notification\",\"close\",\"iconComponent\",\"contentComponent\",\"closeComponent\"],[[28,[37,6],[[33,7]],null],[30,1],[28,[37,8],[[30,0],\"remove\",[30,1]],null],[28,[37,6],[[33,9]],null],[28,[37,6],[[33,10]],null],[28,[37,6],[[33,11]],null]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[\"notification\",\"&default\"],false,[\"each\",\"-track-array\",\"notifications\",\"if\",\"has-block\",\"ember-notifier-notification\",\"readonly\",\"notificationClass\",\"action\",\"iconComponent\",\"contentComponent\",\"closeComponent\",\"yield\"]]",
    "moduleName": "ember-notifier/components/ember-notifier/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});