define("ember-animated/motions/adjust-color", ["exports", "ember-animated", "ember-animated/color"], function (_exports, _emberAnimated, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = adjustColor;
  _exports.AdjustColor = void 0;

  function adjustColor(propertyName, sprite, opts) {
    return new AdjustColor(propertyName, sprite, opts).run();
  }

  adjustColor.property = function (propertyName) {
    return this.bind(null, propertyName);
  };

  class AdjustColor extends _emberAnimated.Motion {
    constructor(propertyName, sprite, opts) {
      super(sprite, opts);
      this.propertyName = propertyName;
      this.colorTween = null;
    }

    *animate() {
      let from, to;

      if (this.opts.from != null) {
        // user-provided choice takes precedence
        from = _color.Color.fromUserProvidedColor(this.opts.from);
      } else if (this.sprite.initialComputedStyle) {
        // otherwise our initial color defaults to the measured initial style
        from = _color.Color.fromComputedStyle(this.sprite.initialComputedStyle[this.propertyName]);
      } else {
        // if we don't have a measured initial style, we use the final
        // style. This makes sense in cases where somebody is animating
        // an insertedSprite to an explicit color, and they expect the
        // "from" value to just match the way the sprite will
        // look when it's normal.
        from = _color.Color.fromComputedStyle(this.sprite.finalComputedStyle[this.propertyName]);
      }

      if (this.opts.to != null) {
        to = _color.Color.fromUserProvidedColor(this.opts.to);
      } else if (this.sprite.finalComputedStyle) {
        to = _color.Color.fromComputedStyle(this.sprite.finalComputedStyle[this.propertyName]);
      } else {
        to = _color.Color.fromComputedStyle(this.sprite.initialComputedStyle[this.propertyName]);
      }

      this.colorTween = new _color.ColorTween(from, to, this.duration, this.opts.easing);

      while (!this.colorTween.done) {
        this.sprite.applyStyles({
          [this.propertyName]: this.colorTween.currentValue.toString()
        });
        yield (0, _emberAnimated.rAF)();
      }
    }

  }

  _exports.AdjustColor = AdjustColor;
});