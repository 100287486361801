define("ember-could-get-used-to-this/-private/modifiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modifier = modifier;
  _exports.Modifier = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FunctionalModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.22'));
    }

    createModifier(fn, args) {
      return {
        fn,
        args,
        element: undefined,
        destructor: undefined
      };
    }

    installModifier(state, element) {
      state.element = element;
      this.setupModifier(state);
    }

    updateModifier(state) {
      this.destroyModifier(state);
      this.setupModifier(state);
    }

    setupModifier(state) {
      let {
        fn,
        args,
        element
      } = state;
      state.destructor = fn(element, args.positional, args.named);
    }

    destroyModifier(state) {
      if (typeof state.destructor === 'function') {
        state.destructor();
      }
    }

    getDebugName(fn) {
      return fn.name || '(anonymous function)';
    }

  }

  const FUNCTIONAL_MODIFIER_MANAGER = new FunctionalModifierManager();

  const FUNCTIONAL_MODIFIER_MANAGER_FACTORY = () => FUNCTIONAL_MODIFIER_MANAGER;

  function modifier(fn) {
    return Ember._setModifierManager(FUNCTIONAL_MODIFIER_MANAGER_FACTORY, fn);
  } ////////////


  class Modifier {
    constructor(owner, args) {
      Ember.setOwner(this, owner);
      this.args = args;
    }

    setup() {}

  }

  _exports.Modifier = Modifier;

  class ClassModifierManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.22'));

      this.owner = owner;
    }

    createModifier(Class, args) {
      let instance = new Class(this.owner, args);
      return {
        Class,
        instance,
        args,
        element: undefined
      };
    }

    installModifier(state, element) {
      state.element = element;
      this.setupModifier(state);
    }

    updateModifier(state) {
      if (typeof state.instance.update === 'function') {
        state.instance.update();
      } else {
        this.destroyModifier(state);
        let {
          Class,
          args
        } = state;
        state.instance = new Class(this.owner, args);
        this.setupModifier(state);
      }
    }

    setupModifier({
      instance,
      element
    }) {
      instance.element = element;
      instance.setup();

      if (typeof instance.teardown === 'function') {
        Ember._registerDestructor(instance, () => instance.teardown());
      }
    }

    destroyModifier(state) {
      Ember.destroy(state.instance);
    }

    getDebugName(Class) {
      return Class.name || '(anonymous class)';
    }

  }

  Ember._setModifierManager(owner => new ClassModifierManager(owner), Modifier);
});