define("ember-autoresize-modifier/modifiers/autoresize", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AutoresizeModifier = (_dec = Ember._action, _dec2 = Ember._action, (_class = class AutoresizeModifier extends _emberModifier.default {
    resize() {
      let {
        element
      } = this; // height must be calculated independently from height previously enforced

      element.style.height = 'auto';
      let isBorderBox = window.getComputedStyle(element).boxSizing === 'border-box';
      let requiredHeight = element.scrollHeight;

      if (isBorderBox) {
        // borders must be added on top of scrollHeight if box-sizing is border-box
        let borderHeight = element.offsetHeight - element.clientHeight;
        requiredHeight += borderHeight;
      }

      element.style.height = `${requiredHeight}px`;
    }

    scheduleResize() {
      Ember.run.scheduleOnce('afterRender', this, 'resize');
    }

    didInstall() {
      // resize for initial value
      this.scheduleResize(); // resize on every input event

      this.element.addEventListener('input', this.scheduleResize);
    }

    didUpdateArguments() {
      // resize when arguments changes
      this.scheduleResize();
    }

    willRemove() {
      // clean up
      this.element.removeEventListener('input', this.scheduleResize);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "resize", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "resize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scheduleResize", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "scheduleResize"), _class.prototype)), _class));
  _exports.default = AutoresizeModifier;
});