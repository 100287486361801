define("ember-cli-string-helpers/helpers/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanize = humanize;
  _exports.default = void 0;
  const regex = /_+|-+/g;
  const replacement = ' '; // The substituted value will be contained in the result variable

  function humanize([string]) {
    if (Ember.String.isHTMLSafe(string)) {
      string = string.string;
    }

    if (string === undefined || string === null) {
      return '';
    }

    let result = string.toLowerCase().replace(regex, replacement);
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  var _default = Ember.Helper.helper(humanize);

  _exports.default = _default;
});