define("@glimmer/env", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CI = _exports.DEBUG = void 0;
  var DEBUG = false;
  _exports.DEBUG = DEBUG;
  var CI = false;
  _exports.CI = CI;
});