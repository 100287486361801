define("ember-animated/-private/partition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = partition;

  function partition(iterator, predicate) {
    let truthy = [];
    let falsy = [];

    for (let item of iterator) {
      if (predicate(item)) {
        truthy.push(item);
      } else {
        falsy.push(item);
      }
    }

    return [truthy, falsy];
  }
});