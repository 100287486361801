define("ember-keyboard/utils/get-code", ["exports", "ember-keyboard/fixtures/code-map"], function (_exports, _codeMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getCode;

  function getCode(event) {
    const {
      code,
      key,
      keyCode
    } = event; // Note that keyCode is deprecated
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode

    if (!code) {
      return _codeMap.default[keyCode];
    }

    if (!code.includes('Key') || !key) {
      return _codeMap.default[keyCode] || code;
    } // If we have a software-applied key-remapping
    // For example:
    //   in Dvorak:
    //     pressing 'k'
    //       will give a code of 'KeyV'
    //       and a key of 'k'


    const codeLetter = code.charAt(code.length - 1);
    const keyboardLetter = codeLetter.toLowerCase();
    const typedLetter = key.toLowerCase();

    if (typedLetter === keyboardLetter) {
      return code;
    }

    const newCode = `Key${typedLetter.toUpperCase()}`;
    return newCode;
  }
});