define("ember-composable-helpers/helpers/take", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.take = take;
  _exports.default = void 0;

  function take([takeAmount, array]) {
    return (0, _asArray.default)(array).slice(0, takeAmount);
  }

  var _default = Ember.Helper.helper(take);

  _exports.default = _default;
});