define("ember-toggle/components/x-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZrjiiQgN",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"switch\",\"offLabel\",\"onLabel\",\"label\"],[[50,\"x-toggle-switch\",0,null,[[\"disabled\",\"forId\",\"name\",\"offLabel\",\"onLabel\",\"size\",\"sendToggle\",\"theme\",\"toggled\",\"value\"],[[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[28,[37,11],[[30,0],\"sendToggle\"],null],[33,12],[33,13],[33,14]]]],[50,\"x-toggle-label\",0,null,[[\"label\",\"sendToggle\",\"show\",\"switchId\",\"value\"],[[33,8],[28,[37,11],[[30,0],\"sendToggle\"],null],[33,15],[33,6],false]]],[50,\"x-toggle-label\",0,null,[[\"label\",\"sendToggle\",\"show\",\"switchId\",\"value\"],[[33,9],[28,[37,11],[[30,0],\"sendToggle\"],null],[33,15],[33,6],true]]],[50,\"x-toggle-label\",0,null,[[\"sendToggle\",\"show\",\"switchId\"],[[28,[37,11],[[30,0],\"sendToggle\"],null],[33,15],[33,6]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,16],null,[[\"label\",\"show\",\"switchId\",\"value\",\"sendToggle\"],[[33,8],[33,15],[33,6],false,[28,[37,11],[[30,0],\"sendToggle\"],null]]]]],[1,\"\\n\\n  \"],[1,[28,[35,17],null,[[\"disabled\",\"forId\",\"name\",\"offLabel\",\"onLabel\",\"size\",\"theme\",\"toggled\",\"value\",\"sendToggle\"],[[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,12],[33,13],[33,14],[28,[37,11],[[30,0],\"sendToggle\"],null]]]]],[1,\"\\n\\n  \"],[1,[28,[35,16],null,[[\"label\",\"show\",\"switchId\",\"value\",\"sendToggle\"],[[33,9],[33,15],[33,6],true,[28,[37,11],[[30,0],\"sendToggle\"],null]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"disabled\",\"forId\",\"name\",\"offLabel\",\"onLabel\",\"size\",\"action\",\"theme\",\"toggled\",\"value\",\"showLabels\",\"x-toggle-label\",\"x-toggle-switch\"]]",
    "moduleName": "ember-toggle/components/x-toggle/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});