define("ember-truth-helpers/helpers/lt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lt = lt;
  _exports.default = void 0;

  function lt([left, right], hash) {
    if (hash.forceNumber) {
      if (typeof left !== 'number') {
        left = Number(left);
      }

      if (typeof right !== 'number') {
        right = Number(right);
      }
    }

    return left < right;
  }

  var _default = Ember.Helper.helper(lt);

  _exports.default = _default;
});