define("ember-composable-helpers/helpers/filter", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filter = filter;
  _exports.default = void 0;

  function filter([callback, array]) {
    if (Ember.isEmpty(callback) || !array) {
      return [];
    }

    return (0, _asArray.default)(array).filter(callback);
  }

  var _default = Ember.Helper.helper(filter);

  _exports.default = _default;
});