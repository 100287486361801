define("ember-keyboard/fixtures/code-maps/chromium/linux", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    47: 'Help',
    42: 'PrintScreen',
    108: 'NumpadDecimal',
    187: 'NumpadEqual'
  };
  _exports.default = _default;
});