define("ember-composable-helpers/helpers/pick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function event([path, action]
  /*, hash*/
  ) {
    return function (event) {
      let value = Ember.get(event, path);

      if (!action) {
        return value;
      }

      action(value);
    };
  });

  _exports.default = _default;
});