define("ember-animated/-private/motion-bridge", ["exports", "ember-animated/-private/concurrency-helpers"], function (_exports, _concurrencyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.continueMotions = continueMotions;
  _exports.continuedFromElement = continuedFromElement;
  const bridges = new WeakMap();

  function continueMotions(oldElement, newElement) {
    bridges.set(newElement, oldElement);
    (0, _concurrencyHelpers.rAF)().then(() => {
      if (bridges.get(newElement) === oldElement) {
        bridges.delete(newElement);
      }
    });
  }

  function continuedFromElement(newElement) {
    return bridges.get(newElement);
  }
});