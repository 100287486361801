define("tracked-built-ins/-private/array", ["exports", "tracked-maps-and-sets/-private/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ARRAY_GETTER_METHODS = new Set([Symbol.iterator, 'concat', 'entries', 'every', 'fill', 'filter', 'find', 'findIndex', 'flat', 'flatMap', 'forEach', 'includes', 'indexOf', 'join', 'keys', 'lastIndexOf', 'map', 'reduce', 'reduceRight', 'slice', 'some', 'values']);

  function convertToInt(prop) {
    if (typeof prop === 'symbol') return null;
    const num = Number(prop);
    if (isNaN(num)) return null;
    return num % 1 === 0 ? num : null;
  }

  function createArrayProxy(arr) {
    let indexTags = [];
    let boundFns = new Map();
    return new Proxy(arr, {
      get(target, prop, receiver) {
        let index = convertToInt(prop);

        if (index !== null) {
          let tag = indexTags[index];

          if (tag === undefined) {
            tag = indexTags[index] = (0, _util.createTag)();
          }

          (0, _util.consumeTag)(tag);
          (0, _util.consumeCollection)(receiver);
          return target[index];
        } else if (prop === 'length') {
          (0, _util.consumeCollection)(receiver);
        } else if (ARRAY_GETTER_METHODS.has(prop)) {
          let fn = boundFns.get(prop);

          if (fn === undefined) {
            fn = (...args) => {
              (0, _util.consumeCollection)(receiver);
              return target[prop](...args);
            };

            boundFns.set(prop, fn);
          }

          return fn;
        }

        return target[prop];
      },

      set(target, prop, value, receiver) {
        target[prop] = value;
        let index = convertToInt(prop);

        if (index !== null) {
          let tag = indexTags[index];

          if (tag !== undefined) {
            (0, _util.dirtyTag)(tag);
          }

          (0, _util.dirtyCollection)(receiver);
        } else if (prop === 'length') {
          (0, _util.dirtyCollection)(receiver);
        }

        return true;
      },

      getPrototypeOf() {
        return TrackedArray.prototype;
      }

    });
  }

  class TrackedArray {
    static from(it) {
      return createArrayProxy(Array.from(it));
    }

    static of(...arr) {
      return createArrayProxy(arr);
    }

    constructor(arr = []) {
      return createArrayProxy(arr.slice());
    }

  } // Ensure instanceof works correctly


  _exports.default = TrackedArray;
  Object.setPrototypeOf(TrackedArray.prototype, Array.prototype);
});