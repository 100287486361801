define("ember-animated-tools/reexported/time-control", ["exports", "ember-animated"], function (_exports, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let origNow = _emberAnimated.clock.now;

  class TimeControl {
    constructor() {
      if (_emberAnimated.clock.now !== origNow) {
        throw new Error('Only one TimeControl may be active at a time');
      }

      this._timer = origNow();
      this._runningSpeed = false;
      this._runStartedAt = null;

      _emberAnimated.clock.now = () => this.now();
    }

    finished() {
      _emberAnimated.clock.now = origNow;
    }

    now() {
      if (this._runningSpeed) {
        return (origNow() - this._runStartedAt) * this._runningSpeed + this._timer;
      }

      return this._timer;
    }

    advance(ms) {
      if (this._runningSpeed) {
        throw new Error("You can't advance a running TimeControl. Use either runAtSpeed or advance but not both at once.");
      }

      this._timer += ms; // This waits for three frames because:
      //
      //   1. You need at least two rAFs to guarantee that everybody
      //   else who is running at rAF frequency had a chance to run
      //   (because you don't know which ones ran before you in the same
      //   frame).
      //
      //   2. Our Tween system doesn't mark Tweens as done until they
      //   had a whole frame in their "done" state (see comments in
      //   ./tween.js).

      return (0, _emberAnimated.rAF)().then(_emberAnimated.rAF).then(_emberAnimated.rAF);
    }

    runAtSpeed(factor) {
      this._timer = this.now();
      this._runningSpeed = factor;
      this._runStartedAt = origNow();
    }

    pause() {
      this._timer = this.now();
      this._runningSpeed = false;
      this._runstartedAt = null;
    }

  }

  _exports.default = TimeControl;
});