define("liquid-fire/constraint", ["exports", "liquid-fire/constrainables"], function (_exports, _constrainables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.constraintKeys = constraintKeys;
  _exports.ANY = _exports.EMPTY = _exports.default = void 0;

  // Every rule constraint has a target and either `keys` or
  // `predicate`. key-based constraints are cheaper because we can check
  // them with O(1) lookups, whereas predicates must be searched O(n).
  class Constraint {
    constructor(target, matcher) {
      // targets are the properties of a transition that we can
      // constrain
      this.target = target;

      if (arguments.length === 1) {
        return;
      }

      if (matcher instanceof RegExp) {
        this.predicate = function (value) {
          return matcher.test(value);
        };
      } else if (typeof matcher === 'function') {
        this.predicate = matcher;
      } else if (typeof matcher === 'boolean') {
        this.predicate = function (value) {
          return matcher ? value : !value;
        };
      } else {
        this.keys = constraintKeys(matcher);
      }
    }

    invert() {
      if (!_constrainables.default[this.target].reversesTo) {
        return this;
      }

      let inverse = new this.constructor(_constrainables.default[this.target].reversesTo);
      inverse.predicate = this.predicate;
      inverse.keys = this.keys;
      return inverse;
    }

  }

  _exports.default = Constraint;
  const EMPTY = '__liquid_fire_EMPTY__';
  _exports.EMPTY = EMPTY;
  const ANY = '__liquid_fire_ANY__';
  _exports.ANY = ANY;

  function constraintKeys(matcher) {
    if (typeof matcher === 'undefined' || matcher === null) {
      matcher = [EMPTY];
    } else if (!Ember.isArray(matcher)) {
      matcher = [matcher];
    }

    return Ember.A(matcher).map(elt => {
      if (typeof elt === 'string') {
        return elt;
      } else {
        return Ember.guidFor(elt);
      }
    });
  }
});