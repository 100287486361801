define("ember-responsive/helpers/media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    init() {
      this._super(...arguments);

      this.get('media').on('mediaChanged', () => {
        this.recompute();
      });
    },

    media: Ember.inject.service(),

    compute([prop]) {
      return Ember.get(this, `media.${prop}`);
    }

  });

  _exports.default = _default;
});