define("liquid-fire/helpers/lf-or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lfOr = lfOr;
  _exports.default = void 0;

  function lfOr(params
  /*, hash*/
  ) {
    return params.reduce((a, b) => a || b, false);
  }

  var _default = Ember.Helper.helper(lfOr);

  _exports.default = _default;
});