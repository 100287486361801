define("ember-assign-helper/helpers/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assign = assign;
  _exports.default = void 0;

  function assign(params) {
    return Ember.assign({}, ...params);
  }

  var _default = Ember.Helper.helper(assign);

  _exports.default = _default;
});