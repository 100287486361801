define("ember-animated/components/animated-if", ["exports", "ember-animated/templates/components/animated-if"], function (_exports, _animatedIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
    A drop in replacement for `{{#if}}` that animates changes when the predicate changes.
    Animated-if uses the same arguments as animated-each.
    ```hbs
    <button {{action toggleThing}}>Toggle</button>
  
    {{#animated-if showThing use=transition}}
        <div class="message" {{action "toggleThing"}}>
            myContent
        </div>
    {{/animated-if}}
    ```
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import { easeOut, easeIn } from 'ember-animated/easings/cosine';
  
    export default Component.extend({
      showThing: false,
  
      toggleThing() {
        this.set('showThing', !this.get('showThing'));
      },
  
      transition: function * ({ insertedSprites, keptSprites, removedSprites }) {
        for (let sprite of insertedSprites) {
          sprite.startAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeOut });
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeIn });
        }
      },
    });
    ```
    @class animated-if
    @public
  */
  let AnimatedIfComponent = (_dec = Ember.computed('group'), (_class = (_temp = _class2 = class AnimatedIfComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "layout", _animatedIf.default);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "group", void 0);
    }

    get realGroup() {
      return this.group || `animated_if_${Math.floor(Math.random() * 1000000)}`;
    }

  }, _defineProperty(_class2, "positionalParams", ['predicate']), _temp), (_applyDecoratedDescriptor(_class.prototype, "realGroup", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "realGroup"), _class.prototype)), _class));
  _exports.default = AnimatedIfComponent;
});