define("ember-power-calendar/components/power-calendar-range/days", ["exports", "ember-power-calendar/components/power-calendar/days", "ember-power-calendar-utils"], function (_exports, _days, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _days.default {
    // Methods
    buildDay(date, today, calendar) {
      let day = super.buildDay(...arguments);
      let {
        start,
        end
      } = Ember.getProperties(calendar.selected || {
        start: null,
        end: null
      }, 'start', 'end');

      if (start && end) {
        day.isSelected = (0, _emberPowerCalendarUtils.isBetween)(date, start, end, 'day', '[]');
        day.isRangeStart = day.isSelected && (0, _emberPowerCalendarUtils.isSame)(date, start, 'day');
        day.isRangeEnd = day.isSelected && (0, _emberPowerCalendarUtils.isSame)(date, end, 'day');
      } else {
        day.isRangeEnd = false;

        if (!start) {
          day.isRangeStart = false;
        } else {
          day.isRangeStart = day.isSelected = (0, _emberPowerCalendarUtils.isSame)(date, start, 'day');

          if (!day.isDisabled) {
            let diffInMs = Math.abs((0, _emberPowerCalendarUtils.diff)(day.date, start));
            day.isDisabled = diffInMs < calendar.minRange || calendar.maxRange !== null && diffInMs > calendar.maxRange;
          }
        }
      }

      return day;
    }

    dayIsSelected() {
      return false;
    }

  }

  _exports.default = _default;
});