define("ember-cli-string-helpers/-private/create-string-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(stringFunction) {
    return function ([string]) {
      if (Ember.String.isHTMLSafe(string)) {
        string = string.string;
      }

      string = string || '';
      return stringFunction(string);
    };
  }
});