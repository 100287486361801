define("ember-table/components/ember-td/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9i8Dd52t",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"et-cell-container\"],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"      \"],[10,1],[15,0,[29,[\"et-toggle-select \",[52,[51,[33,4]],\"et-speech-only\"]]]],[14,\"data-test-select-row-container\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"data-test-select-row\",\"checked\",\"onClick\",\"ariaLabel\"],[true,[33,6,[\"isGroupSelected\"]],[28,[37,7],[[30,0],\"onSelectionToggled\"],null],\"Select row\"]]]],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,8],[[[1,\"      \"],[10,1],[15,0,[29,[\"et-toggle-collapse et-depth-indent \",[36,9]]]],[12],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"data-test-collapse-row\",\"checked\",\"onChange\",\"ariaLabel\"],[true,[33,6,[\"isCollapsed\"]],[28,[37,7],[[30,0],\"onCollapseToggled\"],null],\"Collapse row\"]]]],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"et-depth-indent et-depth-placeholder \",[36,9]]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,0],[14,0,\"et-cell-content\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"        \"],[18,1,[[33,12],[33,13],[33,14],[33,15],[33,16],[33,6]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[34,12]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[33,12],[33,13],[33,14],[33,15],[33,16],[33,6]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,12]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"isFirstColumn\",\"canSelect\",\"unless\",\"shouldShowCheckbox\",\"-ember-table-private/simple-checkbox\",\"rowMeta\",\"action\",\"canCollapse\",\"depthClass\",\"has-block\",\"yield\",\"cellValue\",\"columnValue\",\"rowValue\",\"cellMeta\",\"columnMeta\"]]",
    "moduleName": "ember-table/components/ember-td/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});