define("ember-statecharts/index", ["exports", "ember-statecharts/usables/use-machine", "xstate"], function (_exports, _useMachine, _xstate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.matchesState = matchesState;
  _exports.interpreterFor = interpreterFor;
  Object.defineProperty(_exports, "useMachine", {
    enumerable: true,
    get: function () {
      return _useMachine.default;
    }
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function matchesState(state, statechartPropertyName = 'statechart') {
    return function () {
      return {
        get() {
          const statechart = this[statechartPropertyName];

          if (statechart) {
            return (0, _xstate.matchesState)(state, statechart.state.value);
          } else {
            return false;
          }
        }

      };
    };
  }
  /**
   * No-op typecast function that turns what TypeScript believes to be a
   * ConfigurableMachineDefinition function into a InterpreterUsable.
   *
   * ```js
   * import { useMachine, interpreterFor } from 'ember-statecharts';
   *
   * class Foo extends EmberObject {
   *   @use statechart = useMachine(...) {
   *     // ...
   *   }
   *
   *   someMethod() {
   *     this.statechart.send('WAT'); // TypeError
   *     interpreterFor(this.statechart).send('WAT'); // ok!
   *   }
   * }
   * ```
   *
   * @param configurableMachineDefinition The ConfigurableMachineDefinition used
   * to initialize the `useMachine`-usable via `@use`
   *
   * Note that this is purely a typecast function.
   */


  function interpreterFor(configurableMachineDefinition) {
    return configurableMachineDefinition;
  }
});