define("ember-validators/utils/validation-error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validationError;
  function validationError(type, value, context, message) {
    return { type, value, context, message };
  }
});