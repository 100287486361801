define("ember-query-params-service/utils/query-param/index", ["exports", "@ember-decorators/utils/decorator", "ember-query-params-service/utils/query-param/helpers"], function (_exports, _decorator, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryParam = void 0;
  // type DecoratorCreator = (...args: Args<string>) => PropertyDecorator;
  // type DecoratorWithParams = PropertyDecorator | DecoratorCreator;
  const queryParam = (0, _decorator.decoratorWithParams)(queryParamWithOptionalParams)
  /* ugh */
  ;
  _exports.queryParam = queryParam;

  function queryParamWithOptionalParams(_target, propertyKey, sourceDescriptor, ...args) {
    const {
      get: oldGet,
      initializer
    } = sourceDescriptor;
    const [propertyPath, options] = (0, _helpers.extractArgs)(args, propertyKey); // There is no initializer, so stage 1 decorators actually
    // don't have the capability to do what I want :(
    // setupController(target);
    //
    // this means that in order to use any query param willy-nilly
    // we'll need to prevent the router from looking up the controller
    // to remove un-specified query params

    const result = {
      configurable: true,
      enumerable: true,
      get: function () {
        // setupController(this, 'application');
        const service = (0, _helpers.ensureService)(this);
        const value = Ember.get(service, propertyPath);
        const deserialized = (0, _helpers.tryDeserialize)(value, options);
        return deserialized || (oldGet === null || oldGet === void 0 ? void 0 : oldGet()) || (initializer === null || initializer === void 0 ? void 0 : initializer());
      },
      set: function (value) {
        // setupController(this, 'application');
        const service = (0, _helpers.ensureService)(this);
        const serialized = (0, _helpers.trySerialize)(value, options);
        Ember.set(service, propertyPath, serialized);
      }
    };
    return result;
  }
});