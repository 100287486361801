define("ember-cli-string-helpers/helpers/underscore", ["exports", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.underscore = void 0;
  const underscore = (0, _createStringHelper.default)(Ember.String.underscore);
  _exports.underscore = underscore;

  var _default = Ember.Helper.helper(underscore);

  _exports.default = _default;
});