define("tracked-built-ins/-private/decorator", ["exports", "tracked-maps-and-sets", "tracked-built-ins/-private/array", "tracked-built-ins/-private/object"], function (_exports, _trackedMapsAndSets, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tracked;

  function tracked(obj, key, desc) {
    if (key !== undefined && desc !== undefined) {
      return Ember._tracked(obj, key, desc);
    }

    if (Array.isArray(obj)) {
      return new _array.default(obj);
    }

    switch (obj) {
      case Object:
        return new _object.default();

      case Array:
        return new _array.default();

      case Map:
        return new _trackedMapsAndSets.TrackedMap();

      case WeakMap:
        return new _trackedMapsAndSets.TrackedWeakMap();

      case Set:
        return new _trackedMapsAndSets.TrackedSet();

      case WeakSet:
        return new _trackedMapsAndSets.TrackedWeakSet();
    }

    if (obj instanceof Map) {
      return new _trackedMapsAndSets.TrackedMap(obj);
    } else if (obj instanceof WeakMap) {
      return new _trackedMapsAndSets.TrackedWeakMap();
    } else if (obj instanceof Set) {
      return new _trackedMapsAndSets.TrackedSet(obj);
    } else if (obj instanceof WeakSet) {
      return new _trackedMapsAndSets.TrackedWeakSet();
    } else {
      (false && !(typeof obj === 'object' && obj !== null) && Ember.assert(`You must either use tracked as a field decorator, or to wrap built-in class instances:

      class Example {
        @tracked field = 123;

        map = tracked(Map);
        map = tracked(new Map());
      }`, typeof obj === 'object' && obj !== null));
      return new _object.default(obj);
    }
  }
});