define('ember-validators/confirmation', ['exports', 'ember-validators/utils/validation-error'], function (exports, _validationError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateConfirmation;


  /**
   *  @class Confirmation
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {String} options.on The attribute to confirm against
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {Object} model
   * @param {String} attribute
   */
  function validateConfirmation(value, options, model, attribute) {
    let on = Ember.get(options, 'on');
    let allowBlank = Ember.get(options, 'allowBlank');

    (false && !(Ember.isPresent(on)) && Ember.assert(`[validator:confirmation] [${attribute}] option 'on' is required`, Ember.isPresent(on)));


    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    if (!Ember.isEqual(value, Ember.get(model, on))) {
      return (0, _validationError.default)('confirmation', value, options);
    }

    return true;
  }
});