define("ember-notifier/components/ember-notifier-notification/component", ["exports", "ember-notifier/mixins/swipe-support", "ember-notifier/utils/default-value", "ember-notifier/components/ember-notifier-notification/template"], function (_exports, _swipeSupport, _defaultValue, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The notification component which displays the icon, content, and close button.
   *
   * Inline usage:
   * ```hbs
   * {{ember-notifier-notification
   *   notification=notification
   *   close=(action "remove" notification)}}
   * ```
   *
   * Custom notification components:
   * ```hbs
   * {{ember-notifier-notification
   *   notification=notification
   *   close=(action "remove" notification)
   *   iconComponent='my-icon-component'
   *   contentComponent='my-content-component'
   *   closeComponent='my-close-component'}}
   * ```
   *
   * If the mouse enters the notification and the notification is scheduled to be removed, it will be
   * paused and then restarted when the mouse leaves. If the user swipes right, the notification will
   * be closed.
   *
   * @class EmberNotifierNotification
   */
  var _default = Ember.Component.extend(_swipeSupport.default, {
    layout: _template.default,
    notifier: Ember.inject.service(),
    classNames: ['ember-notifier-notification-base'],
    classNameBindings: ['typeClass', 'animationStateClass'],
    attributeBindings: ['role'],
    role: 'alert',

    /**
     * The notification options object.
     *
     * @argument notification
     * @type !Object
     */
    notification: undefined,

    /**
     * Closure action to remove the notification.
     *
     * @argument close
     * @type ember/action
     */
    close: null,

    /**
     * The notification type class name.
     *
     * @argument typeClass
     * @type string
     * @readOnly
     */
    typeClass: Ember.computed.readOnly('notification.type'),

    /**
     * The notification animation state class name.
     *
     * @argument animationStateClass
     * @type string
     * @readOnly
     */
    animationStateClass: Ember.computed.readOnly('notification.animationState'),

    /**
     * The number of pixels a user must move before notification will close.
     *
     * @argument minSwipeDistance
     * @type number
     * @readOnly
     */
    swipeThreshold: Ember.computed.readOnly('notification.minSwipeDistance'),

    /**
     * The number of milliseconds a user must move before notification will close.
     *
     * @argument maxSwipeTime
     * @type number
     * @readOnly
     */
    swipeTimeout: Ember.computed.readOnly('notification.maxSwipeTime'),

    /**
     * The swipe direction, "left" or "right", to close a notification.
     *
     * @argument swipeDirection
     * @type string
     * @readOnly
     */
    swipeDirection: Ember.computed.readOnly('notification.swipeDirection'),

    /**
     * The icon component to render.
     *
     * Default is: `ember-notifier-notification/icon`.
     *
     * @argument iconComponent
     * @type string
     */
    iconComponent: (0, _defaultValue.default)('ember-notifier-notification/icon'),

    /**
     * The content component to render.
     *
     * Default is: `ember-notifier-notification/content`.
     *
     * @argument contentComponent
     * @type string
     */
    contentComponent: (0, _defaultValue.default)('ember-notifier-notification/content'),

    /**
     * The close component to render.
     *
     * Default is: `ember-notifier-notification/close`.
     *
     * @argument closeComponent
     * @type string
     */
    closeComponent: (0, _defaultValue.default)('ember-notifier-notification/close'),

    willDestroyElement() {
      this._super(...arguments);

      const notification = this.get('notification');

      if (notification.get('timer')) {
        this.get('notifier').cancelRemoval(notification);
      }
    },

    actions: {
      setOption(property, value) {
        this.get('notification').set(property, value);
      }

    },

    mouseEnter()
    /* event */
    {
      const notification = this.get('notification');

      if (notification.get('duration') > 0) {
        this.get('notifier').cancelRemoval(notification);
      }
    },

    mouseLeave()
    /* event */
    {
      const notification = this.get('notification');

      if (notification.get('duration') > 0) {
        this.get('notifier').scheduleRemoval(notification);
      }
    },

    onSwipe(direction) {
      if (this.get('swipeDirection') === direction) {
        this.close();
      }
    }

  });

  _exports.default = _default;
});