define("ember-table/components/ember-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AuqFm4db",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[28,[37,6],null,[[\"cells\",\"isHeader\",\"row\"],[[30,1,[\"cells\"]],[30,1,[\"isHeader\"]],[50,\"ember-tr\",0,null,[[\"api\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,8],null,[[\"api\"],[[30,1]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[\"api\",\"&default\"],false,[\"each\",\"-track-array\",\"wrappedRows\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-tr\"]]",
    "moduleName": "ember-table/components/ember-thead/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});