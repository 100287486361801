define("ember-animated-tools/templates/components/animated-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pygfAv5u",
    "block": "[[[41,[51,[30,0,[\"isHidden\"]]],[[[1,\"  \"],[10,0],[15,0,[29,[\"animated-tools \",[52,[30,0,[\"isOpen\"]],\"is-open\"]]]],[12],[1,\"\\n    \"],[11,0],[24,0,\"animated-tools-launcher\"],[4,[38,2],[[30,0],\"toggle\"],null],[12],[1,\"\\n      \"],[1,[34,3]],[1,\"\\n      ⏱\"],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"animated-tools-panel\"],[12],[1,\"\\n      \"],[1,[34,4]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"if\",\"action\",\"motion-indicator\",\"time-control\"]]",
    "moduleName": "ember-animated-tools/templates/components/animated-tools.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});