define('ember-sortable/mixins/sortable-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  const Mixin = Ember.Mixin,
        $ = Ember.$,
        run = Ember.run;
  const Promise = Ember.RSVP.Promise;
  exports.default = Mixin.create({
    classNames: ['sortable-item'],
    classNameBindings: ['isDragging', 'isDropping'],

    /**
      Group to which the item belongs.
      @property group
      @type SortableGroup
      @default null
    */
    group: null,

    /**
      Model which the item represents.
      @property model
      @type Object
      @default null
    */
    model: null,

    /**
      Selector for the element to use as handle.
      If unset, the entire element will be used as the handle.
      @property handle
      @type String
      @default null
    */
    handle: null,

    /**
      True if the item is currently being dragged.
      @property isDragging
      @type Boolean
      @default false
    */
    isDragging: false,

    /**
      Action that fires when the item starts being dragged.
      @property onDragStart
      @type Action
      @default null
    */
    onDragStart: null,

    /**
      Action that fires when the item stops being dragged.
      @property onDragStop
      @type Action
      @default null
    */
    onDragStop: null,

    /**
      True if the item is currently dropping.
      @property isDropping
      @type Boolean
      @default false
    */
    isDropping: false,

    /**
      True if the item was dropped during the interaction
      @property wasDropped
      @type Boolean
      @default false
    */
    wasDropped: false,

    /**
      @property isBusy
      @type Boolean
    */
    isBusy: Ember.computed.or('isDragging', 'isDropping'),

    /**
      The frequency with which the group is informed
      that an update is required.
      @property updateInterval
      @type Number
      @default 125
    */
    updateInterval: 125,

    /**
      Additional spacing between active item and the rest of the elements.
      @property spacing
      @type Number
      @default 0[px]
    */
    spacing: 0,

    /**
      True if the item transitions with animation.
      @property isAnimated
      @type Boolean
    */
    isAnimated: Ember.computed(function () {
      let el = this.$();
      if (el === undefined || el === null) return false;
      let property = el.css('transition-property');

      return (/all|transform/.test(property)
      );
    }).volatile(),

    /**
      The current transition duration in milliseconds.
      @property transitionDuration
      @type Number
    */
    transitionDuration: Ember.computed(function () {
      let el = this.$();
      if (el === undefined || el === null) return 0;
      let rule = el.css('transition-duration');
      let match = rule.match(/([\d\.]+)([ms]*)/);

      if (match) {
        let value = parseFloat(match[1]);
        let unit = match[2];

        if (unit === 's') {
          value = value * 1000;
        }

        return value;
      }

      return 0;
    }).volatile(),

    /**
      Horizontal position of the item.
      @property x
      @type Number
    */
    x: Ember.computed({
      get() {
        if (this._x === undefined) {
          let el = this.$();
          if (el === undefined || el === null) return 0;
          let marginLeft = parseFloat(el.css('margin-left'));
          this._x = this.element.scrollLeft + this.element.offsetLeft - marginLeft;
        }

        return this._x;
      },
      set(_, value) {
        if (value !== this._x) {
          this._x = value;
          this._scheduleApplyPosition();
        }
      }
    }).volatile(),

    /**
      Vertical position of the item relative to its offset parent.
      @property y
      @type Number
    */
    y: Ember.computed({
      get() {
        if (this._y === undefined) {
          this._y = this.element.offsetTop;
        }

        return this._y;
      },
      set(key, value) {
        if (value !== this._y) {
          this._y = value;
          this._scheduleApplyPosition();
        }
      }
    }).volatile(),

    /**
      Width of the item.
      @property height
      @type Number
    */
    width: Ember.computed(function () {
      let el = this.$();
      if (el === undefined || el === null) return 0;
      let width = el.outerWidth(true);

      width += getBorderSpacing(el).horizontal;

      return width;
    }).volatile(),

    /**
      Height of the item including margins.
      @property height
      @type Number
    */
    height: Ember.computed(function () {
      let el = this.$();
      if (el === undefined || el === null) return 0;
      let height = el.outerHeight();

      let marginBottom = parseFloat(el.css('margin-bottom'));
      height += marginBottom;

      height += getBorderSpacing(el).vertical;

      return height;
    }).volatile(),

    /**
      @method didInsertElement
    */
    didInsertElement() {
      this._super();
      // scheduled to prevent deprecation warning:
      // "never change properties on components, services or models during didInsertElement because it causes significant performance degradation"
      run.schedule("afterRender", this, "_tellGroup", "registerItem", this);
    },

    /**
      @method willDestroyElement
    */
    willDestroyElement() {
      // scheduled to prevent deprecation warning:
      // "never change properties on components, services or models during didInsertElement because it causes significant performance degradation"
      run.schedule("afterRender", this, "_tellGroup", "deregisterItem", this);
    },

    /**
      @method mouseDown
    */
    mouseDown(event) {
      if (event.which !== 1) {
        return;
      }
      if (event.ctrlKey) {
        return;
      }

      this._primeDrag(event);
    },

    /**
      @method touchStart
    */
    touchStart(event) {
      this._primeDrag(event);
    },

    /**
      @method freeze
    */
    freeze() {
      let el = this.$();
      if (!el) {
        return;
      }

      el.css({ transition: 'none' });
      el.height(); // Force-apply styles
    },

    /**
      @method reset
    */
    reset() {
      let el = this.$();
      if (!el) {
        return;
      }

      delete this._y;
      delete this._x;

      el.css({ transform: '' });
      el.height(); // Force-apply styles
    },

    /**
      @method thaw
    */
    thaw() {
      let el = this.$();
      if (!el) {
        return;
      }

      el.css({ transition: '' });
      el.height(); // Force-apply styles
    },

    /**
      @method _primeDrag
      @private
    */
    _primeDrag(event) {
      let handle = this.get('handle');

      if (handle && !$(event.target).closest(handle).length) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      let startDragListener = event => this._startDrag(event);

      function cancelStartDragListener() {
        $(window).off('mousemove touchmove', startDragListener);
      }

      $(window).one('mousemove touchmove', startDragListener);
      $(window).one('click mouseup touchend', cancelStartDragListener);
    },

    /**
      @method _startDrag
      @private
    */
    _startDrag(event) {
      if (this.get('isBusy')) {
        return;
      }

      let drag = this._makeDragHandler(event);

      let drop = () => {
        $(window).off('mousemove touchmove', drag).off('click mouseup touchend', drop);

        this._drop();
      };

      $(window).on('mousemove touchmove', drag).on('click mouseup touchend', drop);

      this._tellGroup('prepare');
      this.set('isDragging', true);
      this.sendAction('onDragStart', this.get('model'));
    },

    /**
      @method _makeDragHandler
      @param {Event} startEvent
      @return {Function}
      @private
    */
    _makeDragHandler(startEvent) {
      const groupDirection = this.get('group.direction');
      let dragOrigin;
      let elementOrigin;
      let scrollOrigin;
      let parentElement = $(this.element.parentNode);

      if (groupDirection === 'x') {
        dragOrigin = getX(startEvent);
        elementOrigin = this.get('x');
        scrollOrigin = parentElement.offset().left;

        return event => {
          let dx = getX(event) - dragOrigin;
          let scrollX = parentElement.offset().left;
          let x = elementOrigin + dx + (scrollOrigin - scrollX);

          this._drag(x);
        };
      }

      if (groupDirection === 'y') {
        dragOrigin = getY(startEvent);
        elementOrigin = this.get('y');
        scrollOrigin = parentElement.offset().top;

        return event => {
          let dy = getY(event) - dragOrigin;
          let scrollY = parentElement.offset().top;
          let y = elementOrigin + dy + (scrollOrigin - scrollY);

          this._drag(y);
        };
      }
    },

    /**
      @method _tellGroup
      @private
    */
    _tellGroup(method, ...args) {
      let group = this.get('group');

      if (group) {
        group[method](...args);
      }
    },

    /**
      @method _scheduleApplyPosition
      @private
    */
    _scheduleApplyPosition() {
      run.scheduleOnce('render', this, '_applyPosition');
    },

    /**
      @method _applyPosition
      @private
    */
    _applyPosition() {
      if (!this.element) {
        return;
      }

      const groupDirection = this.get('group.direction');

      if (groupDirection === 'x') {
        let x = this.get('x');
        let el = this.$();
        if (el === undefined || el === null) return 0;
        let dx = x - this.element.offsetLeft + parseFloat(el.css('margin-left'));

        this.$().css({
          transform: `translateX(${dx}px)`
        });
      }
      if (groupDirection === 'y') {
        let y = this.get('y');
        let dy = y - this.element.offsetTop;
        let el = this.$();
        if (el === undefined || el === null) return 0;
        el.css({
          transform: `translateY(${dy}px)`
        });
      }
    },

    /**
      @method _drag
      @private
    */
    _drag(dimension) {
      let updateInterval = this.get('updateInterval');
      const groupDirection = this.get('group.direction');

      if (groupDirection === 'x') {
        this.set('x', dimension);
      }
      if (groupDirection === 'y') {
        this.set('y', dimension);
      }

      run.throttle(this, '_tellGroup', 'update', updateInterval);
    },

    /**
      @method _drop
      @private
    */
    _drop() {
      if (!this.element) {
        return;
      }

      this._preventClick(this.element);

      this.set('isDragging', false);
      this.set('isDropping', true);

      this._tellGroup('update');

      this._waitForTransition().then(run.bind(this, '_complete'));
    },

    /**
      @method _preventClick
      @private
    */
    _preventClick(element) {
      $(element).one('click', function (e) {
        e.stopImmediatePropagation();
      });
    },

    /**
      @method _waitForTransition
      @private
      @return Promise
    */
    _waitForTransition() {
      return new Promise(resolve => {
        run.next(() => {
          let duration = 0;

          if (this.get('isAnimated')) {
            duration = this.get('transitionDuration');
          }

          run.later(this, resolve, duration);
        });
      });
    },

    /**
      @method _complete
      @private
    */
    _complete() {
      if (this.isDestroyed || this.isDestroying) return;
      this.sendAction('onDragStop', this.get('model'));
      this.set('isDropping', false);
      this.set('wasDropped', true);
      this._tellGroup('commit');
    }
  });


  /**
    Gets the y offset for a given event.
    Work for touch and mouse events.
    @method getY
    @return {Number}
    @private
  */
  function getY(event) {
    let originalEvent = event.originalEvent;
    let touches = originalEvent && originalEvent.changedTouches;
    let touch = touches && touches[0];

    if (touch) {
      return touch.screenY;
    } else {
      return event.pageY;
    }
  }

  /**
    Gets the x offset for a given event.
    @method getX
    @return {Number}
    @private
  */
  function getX(event) {
    let originalEvent = event.originalEvent;
    let touches = originalEvent && originalEvent.changedTouches;
    let touch = touches && touches[0];

    if (touch) {
      return touch.screenX;
    } else {
      return event.pageX;
    }
  }

  /**
    Gets a numeric border-spacing values for a given element.
  
    @method getBorderSpacing
    @param {Element} element
    @return {Object}
    @private
  */
  function getBorderSpacing(el) {
    el = $(el);
    if (el === undefined || el === null) return 0;
    let css = el.css('border-spacing'); // '0px 0px'

    var _css$split = css.split(' '),
        _css$split2 = _slicedToArray(_css$split, 2);

    let horizontal = _css$split2[0],
        vertical = _css$split2[1];


    return {
      horizontal: parseFloat(horizontal),
      vertical: parseFloat(vertical)
    };
  }
});