define('ember-elsewhere/components/to-elsewhere', ['exports', 'ember-elsewhere/templates/components/to-elsewhere'], function (exports, _toElsewhere) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _toElsewhere.default,
    service: Ember.inject.service('ember-elsewhere'),
    tagName: '',
    didReceiveAttrs() {
      if (this.get('name')) {
        throw new Error(`to-elsewhere takes a "named=" parameter, not "name="`);
      }
      this.get('service').show(Ember.guidFor(this), this.get('named'), this.get('send'), this.get('outsideParams'));
    },
    willDestroyElement() {
      this.get('service').clear(Ember.guidFor(this));
    }

  });
});