define("ember-table/components/ember-tfoot/component", ["exports", "ember-table/components/ember-tbody/component", "ember-table/components/ember-tfoot/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The table footer component. This component manages any footer rows which may
    be attached to the table, and has the same API as EmberTBody. It does not
    provide occlusion, because the number of footer rows is expected to be
    relatively small.
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{columns}} />
      <t.body @rows={{rows}} />
  
      <t.foot @rows={{footerRows}} as |f|>
        <f.row />
      </t.foot>
    </EmberTable>
    ```
  
    @yield {object} foot - the API object yielded by the table footer
    @yield {Component} foot.row - The table row component
    @yield {object} foot.rowValue - The value for the currently yielded row
    @yield {object} foot.rowMeta - The meta for the currently yielded row
    @class {{ember-tfoot}}
    @public
  */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'tfoot',
    wrappedRowArray: Ember.computed('wrappedRows.[]', function () {
      let wrappedRows = this.get('wrappedRows');
      let wrappedRowsLength = wrappedRows.get('length');
      let arr = [];

      for (let i = 0; i < wrappedRowsLength; i++) {
        arr.push(wrappedRows.objectAt(i));
      }

      return Ember.A(arr);
    })
  });

  _exports.default = _default;
});