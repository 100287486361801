define("ember-class-based-modifier/-private/modifier-manager", ["exports", "ember-class-based-modifier/-private/modifier-native"], function (_exports, _modifierNative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ClassBasedModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.13'));
    }

    createModifier(factory, args) {
      return factory.create({
        args
      });
    }

    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }

    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      Ember.set(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }

    destroyModifier(instance) {
      instance.willRemove();
      instance.element = null;

      if ((0, _modifierNative.isNative)(instance)) {
        (0, _modifierNative.destroy)(instance);
      } else {
        instance.destroy();
      }
    }

  }

  var _default = new ClassBasedModifierManager();

  _exports.default = _default;
});