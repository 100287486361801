define("ember-keyboard/utils/trigger-event", ["exports", "ember-keyboard/utils/get-cmd-key", "ember-keyboard", "ember-keyboard/fixtures/modifiers-array"], function (_exports, _getCmdKey, _emberKeyboard, _modifiersArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.triggerKeyUp = _exports.triggerKeyPress = _exports.triggerKeyDown = void 0;

  const triggerKeyEvent = function triggerKeyEvent(eventType, rawCode, element) {
    const event = new Event(eventType);
    const parts = rawCode.split('+');
    const [code] = parts.filter(part => !_modifiersArray.default.includes(part));
    const modifiers = parts.filter(part => part !== code);
    const properties = modifiers.reduce((properties, modifier) => {
      modifier = modifier === 'cmd' ? (0, _getCmdKey.default)() : modifier;
      properties[`${modifier}Key`] = true;
      return properties;
    }, {});
    Ember.assign(event, {
      code,
      keyCode: (0, _emberKeyboard.getKeyCode)(code)
    }, properties);
    (element || document).dispatchEvent(event);
  };

  const triggerKeyDown = function triggerKeyDown(code, element) {
    triggerKeyEvent('keydown', code, element);
  };

  _exports.triggerKeyDown = triggerKeyDown;

  const triggerKeyPress = function triggerKeyPress(code, element) {
    triggerKeyEvent('keypress', code, element);
  };

  _exports.triggerKeyPress = triggerKeyPress;

  const triggerKeyUp = function triggerKeyUp(code, element) {
    triggerKeyEvent('keyup', code, element);
  };

  _exports.triggerKeyUp = triggerKeyUp;
});