define("liquid-fire/constrainables", ["exports", "liquid-fire/ember-internals"], function (_exports, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    oldValue: {
      reversesTo: 'newValue',
      accessor: function (conditions) {
        return [versionValue(conditions, 1)];
      }
    },
    newValue: {
      reversesTo: 'oldValue',
      accessor: function (conditions) {
        return [versionValue(conditions, 0)];
      }
    },
    oldRoute: {
      reversesTo: 'newRoute',
      accessor: function (conditions) {
        return (0, _emberInternals.routeName)((0, _emberInternals.childRoute)(versionValue(conditions, 1), conditions.matchContext.outletName));
      }
    },
    newRoute: {
      reversesTo: 'oldRoute',
      accessor: function (conditions) {
        return (0, _emberInternals.routeName)((0, _emberInternals.childRoute)(versionValue(conditions, 0), conditions.matchContext.outletName));
      }
    },
    oldModel: {
      reversesTo: 'newModel',
      accessor: function (conditions) {
        return (0, _emberInternals.routeModel)((0, _emberInternals.childRoute)(versionValue(conditions, 1), conditions.matchContext.outletName));
      }
    },
    newModel: {
      reversesTo: 'oldModel',
      accessor: function (conditions) {
        return (0, _emberInternals.routeModel)((0, _emberInternals.childRoute)(versionValue(conditions, 0), conditions.matchContext.outletName));
      }
    },
    helperName: {
      accessor(conditions) {
        return conditions.matchContext.helperName;
      }

    },
    outletName: {
      accessor(conditions) {
        return conditions.matchContext.outletName;
      }

    },
    parentElementClass: {
      accessor: function (conditions) {
        let cls = conditions.parentElement.attr('class');

        if (cls) {
          return cls.split(/\s+/);
        }
      }
    },
    parentElement: {},
    firstTime: {},
    media: {}
  };
  _exports.default = _default;

  function versionValue(conditions, index) {
    let versions = conditions.versions;
    return versions[index] ? versions[index].value : null;
  }
});