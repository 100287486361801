define('ember-sortable/utils/arrangement', ['exports', 'ember-sortable/utils/slot', 'ember-sortable/utils/identify'], function (exports, _slot, _identify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class Arrangement {

    constructor(rootNode) {
      this.root = new _slot.default(rootNode);
      this.reindex();
    }

    reindex() {
      this.paths = {};
      this.walkTree(({ id }, path) => this.paths[id] = path);
    }

    moveNode(node, point) {
      let id = (0, _identify.default)(node);
      let path = this.paths[id];
      let slot = this.slotForPath(path);
      let oldParentPath = path.slice(0, -1);
      let oldParent = this.slotForPath(oldParentPath);
      let oldPosition = path[path.length - 1];

      var _findHome = this.findHome(slot, point);

      let parent = _findHome.parent,
          position = _findHome.position;


      if (!parent) {
        return;
      }

      if (oldParent === parent && oldPosition === position) {
        return;
      }
      if (oldParent === parent && oldPosition < position) {
        position -= 1;
      }

      let offset = slot.outerHeight + slot.margins.bottom;
      let siblings = parent.children;

      this.detachNode(node);

      siblings.splice(position, 0, slot);
      this.reindex();

      let prevSibling = siblings[position - 1];
      let parentPath = this.paths[parent.id];
      let tail = siblings.slice(position);

      tail.forEach(s => s.translateBy(0, offset));
      this.walkPath(parentPath, s => s.resizeBy(0, offset));

      if (prevSibling) {
        slot.y = prevSibling.bounds.bottom + Math.max(prevSibling.margins.bottom, slot.margins.top);
      } else {
        slot.y = parent.y;
      }
    }

    detachNode(node) {
      let id = (0, _identify.default)(node);
      let path = this.paths[id];
      let parentPath = path.slice(0, -1);
      let index = path[path.length - 1];
      let slot = this.slotForPath(path);
      let parent = this.slotForPath(parentPath);
      let offset = -(slot.outerHeight + slot.margins.bottom);
      let tail = parent.children.slice(index + 1);

      parent.children.splice(index, 1);
      this.reindex();

      tail.forEach(s => s.translateBy(0, offset));
      this.walkPath(parentPath, s => s.resizeBy(0, offset));
    }

    findHome(slot, point) {
      let parent = findParent([this.root], slot, point);
      let slotPoint = { y: slot.oy + point.dy };
      let position = parent && findPosition(parent.children, slotPoint);

      return { parent, position };
    }

    render() {
      this.walkTree(slot => slot.render());
    }

    clear() {
      this.walkTree(slot => slot.clear());
    }

    freeze() {
      this.walkTree(slot => slot.freeze());
    }

    thaw() {
      this.walkTree(slot => slot.thaw());
    }

    slotForPath(path) {
      return path.reduce(({ children }, index) => children[index], { children: [this.root] });
    }

    walkTree(func) {
      walk(this.root, func, [0]);
    }

    walkPath(path, func) {
      let cursor = { children: [this.root] };

      path.forEach(index => {
        cursor = cursor.children[index];
        func(cursor);
      });
    }

    slotForNode(node) {
      let id = (0, _identify.default)(node);
      let path = this.paths[id];
      let result = this.slotForPath(path);

      return result;
    }

    metaFor(node) {
      let id = (0, _identify.default)(node);
      let path = this.paths[id];
      let parentPath = path.slice(0, -1);
      let slot = this.slotForPath(path);
      let parentSlot = this.slotForPath(parentPath);
      let parent = parentSlot.node;
      let position = parentSlot.children.indexOf(slot);

      return { parent, position };
    }
  }

  exports.default = Arrangement;
  function walk(slot, func, path) {
    func(slot, path);

    slot.children.forEach((child, index) => {
      walk(child, func, path.concat(index));
    });
  }

  function findParent(candidates, slot, point) {
    let gatherChildren = (candidates, acc) => {
      candidates.forEach(candidate => {
        if (candidate !== undefined) {
          acc.push(candidate);
        }
        if (candidate.children) {
          gatherChildren(candidate.children, acc);
        }
        return acc;
      });
      return acc;
    };
    let candidatesAndChildren = gatherChildren(candidates, []);

    let candidate = candidatesAndChildren.find(c => {
      return c.canReceiveNode(slot.node) && c.covers(point);
    });

    if (candidate) {
      let sx = slot.ox + point.dx;
      let cx = candidate.x;
      let dx = sx - cx;
      let threshold = 8;
      let shouldDescend = dx >= threshold;
      let child = shouldDescend && findParent(candidate.children, slot, point);

      return child || candidate;
    } else {
      return null;
    }
  }

  function findPosition(slots, point) {
    let all = slots.concat(point).sort((a, b) => a.y - b.y);
    let result = all.indexOf(point);

    return result;
  }
});