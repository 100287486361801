define("ember-keyboard/fixtures/code-maps/gecko", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    59: 'Semicolon',
    173: 'Minus',
    61: 'Equal',
    91: 'OSRight'
  };
  _exports.default = _default;
});