define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RpoTsjBF",
    "block": "[[[10,\"input\"],[14,0,\"x-toggle\"],[15,\"checked\",[36,0]],[15,\"disabled\",[36,1]],[15,1,[36,2]],[15,3,[36,3]],[15,\"onchange\",[28,[37,4],[[30,0],[33,5]],[[\"value\"],[\"target.checked\"]]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\\n\"],[10,\"label\"],[15,\"for\",[36,6]],[12],[1,\"\\n  \"],[10,0],[15,1,[29,[\"x-toggle-visual-\",[36,2]]]],[14,\"role\",\"checkbox\"],[15,0,[29,[\"x-toggle-btn \",[36,7],\" \",[36,8],[52,[33,1],\" x-toggle-disabled\"]]]],[15,\"aria-owns\",[36,2]],[15,\"aria-checked\",[36,0]],[15,\"data-tg-on\",[36,10]],[15,\"data-tg-off\",[36,11]],[12],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"toggled\",\"disabled\",\"forId\",\"name\",\"action\",\"sendToggle\",\"effectiveForId\",\"themeClass\",\"size\",\"if\",\"onLabel\",\"offLabel\"]]",
    "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});