define("ember-statecharts/computed", ["exports", "xstate", "ember-statecharts/utils/statechart"], function (_exports, _xstate, _statechart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statechart = statechart;
  _exports.matchesState = matchesState;
  _exports.debugState = debugState;

  function decorateStopInterpreterOnDestroy(destroyFn, service) {
    return function () {
      service.stop();
      destroyFn.apply(this, ...arguments);
    };
  }

  function matchesState(states, statechartPropertyName = 'statechart') {
    return Ember.computed(`${statechartPropertyName}.currentState`, function () {
      const _states = Ember.A(Ember.makeArray(states));

      return _states.any(state => {
        return (0, _xstate.matchesState)(state, Ember.get(this, `${statechartPropertyName}.currentState.value`));
      });
    });
  }

  function debugState(statechartPropertyName = 'statechart') {
    return Ember.computed(`${statechartPropertyName}.currentState`, function () {
      return JSON.stringify(Ember.get(this, `${statechartPropertyName}.currentState.value`));
    });
  }

  function statechart(config, options) {
    /* eslint-disable ember/require-computed-property-dependencies */
    return Ember.computed(function () {
      const initialContext = this;
      const statechart = new _statechart.default(config, options, initialContext);
      /* eslint-disable ember/no-side-effects */

      this.willDestroy = decorateStopInterpreterOnDestroy(this.willDestroy, statechart.service);
      return statechart;
    });
  }
});