define("ember-truth-helpers/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;

  function isEqual([a, b]) {
    return Ember.isEqual(a, b);
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});