define('ember-elsewhere/services/ember-elsewhere', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init() {
      this._super();
      this.set('actives', Ember.Object.create());
      this._alive = {};
      this._counter = 1;
    },

    show(sourceId, name, component, outsideParams) {
      this._alive[sourceId] = {
        target: name || 'default',
        component,
        order: this._counter++,
        outsideParams
      };
      this._schedule();
    },

    clear(sourceId) {
      delete this._alive[sourceId];
      this._schedule();
    },

    _schedule() {
      Ember.run.scheduleOnce('afterRender', this, this._process);
    },

    _process() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      let newActives = {};
      let alive = this._alive;

      Object.keys(alive).forEach(sourceId => {
        var _alive$sourceId = alive[sourceId];
        let target = _alive$sourceId.target,
            component = _alive$sourceId.component,
            order = _alive$sourceId.order,
            outsideParams = _alive$sourceId.outsideParams;

        newActives[target] = newActives[target] || Ember.A();
        let newActive = component ? { component, order, outsideParams } : null;
        newActives[target].push(newActive);
      });
      Object.keys(newActives).forEach(target => {
        newActives[target] = Ember.A(newActives[target].sortBy('order'));
      });

      this.set('actives', Ember.Object.create(newActives));
    }
  });
});