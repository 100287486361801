define('ember-sortable/utils/manager', ['exports', 'ember-sortable/utils/gesture', 'ember-sortable/utils/arrangement', 'ember-sortable/utils/will-transition', 'ember-sortable/utils/transitionend'], function (exports, _gesture2, _arrangement, _willTransition, _transitionend) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$run = Ember.run;
  const schedule = _Ember$run.schedule,
        debounce = _Ember$run.debounce;


  /**
    @class Manager
    @constructor
    @param {SortableNode} options.node
    @param {Function} options.onComplete
  */
  class Manager {

    constructor({ node, onComplete, onCancel }) {
      this.node = node;
      this.root = getRoot(this.node);
      this.onComplete = onComplete || function () {};
      this.onCancel = onCancel || function () {};
      this.gesture = new _gesture2.default(() => this.update());
      this.arrangement = new _arrangement.default(this.root);
    }

    start(event) {
      this.gesture.start(event);
    }

    update() {
      switch (this.gesture.state) {
        case 'dragging':
          this.drag();
          break;
        case 'dropping':
          this.drop();
          break;
        case 'clicking':
        case 'swiping':
          this.cancel();
          break;
      }
    }

    drag() {
      this.node.set('sortableState', 'dragging');
      this.arrangement.moveNode(this.node, this.gesture);
      this.schedule(() => this.renderDrag());
    }

    drop() {
      this.node.set('sortableState', 'dropping');
      this.schedule(() => this.renderDrop());
    }

    schedule(func) {
      debounce(func, 16);
    }

    renderDrag() {
      var _gesture = this.gesture;
      let dx = _gesture.dx,
          dy = _gesture.dy;
      let style = this.node.element.style;


      this.arrangement.render();

      style.transform = `translate3d(${dx}px, ${dy}px, 10px)`;
    }

    renderDrop() {
      this.arrangement.render();

      if (this.willTransition()) {
        this.node.$().one(_transitionend.default, () => this.completeDrop());
      } else {
        this.completeDrop();
      }
    }

    completeDrop() {
      var _arrangement$metaFor = this.arrangement.metaFor(this.node);

      let parent = _arrangement$metaFor.parent,
          position = _arrangement$metaFor.position;

      this.walk(n => n.set('sortableState', null));
      this.arrangement.freeze();
      requestAnimationFrame(() => {
        this.onComplete(parent, position);
        schedule('afterRender', () => {
          this.arrangement.clear();
          this.arrangement.thaw();
        });
      });
    }

    cancel() {
      this.onCancel();
    }

    walk(func) {
      walk(this.root, func);
    }

    willTransition() {
      var _arrangement$slotForN = this.arrangement.slotForNode(this.node);

      let dx = _arrangement$slotForN.dx,
          dy = _arrangement$slotForN.dy;

      let isOffset = dx !== this.gesture.dx || dy !== this.gesture.dy;

      return isOffset && (0, _willTransition.default)(this.node.element);
    }

  }

  exports.default = Manager;
  function getRoot(node) {
    let root = node;
    while (root.sortableParent) {
      root = root.sortableParent;
    }
    return root;
  }

  function walk(node, func) {
    func(node);
    node.sortableChildren.forEach(n => walk(n, func));
  }
});