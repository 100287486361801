define("ember-could-get-used-to-this/-private/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Resource = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Resource {
    constructor(ownerOrThunk, args) {
      if (typeof ownerOrThunk === 'function') {
        return {
          definition: this.constructor,
          args: ownerOrThunk
        };
      }

      Ember.setOwner(this, ownerOrThunk);
      this.args = args;
    }

    setup() {}

  }

  _exports.Resource = Resource;

  class ResourceManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", Ember._helperManagerCapabilities('3.23', {
        hasValue: true,
        hasDestroyable: true
      }));

      this.owner = owner;
    }

    createHelper(Class, args) {
      let {
        update,
        teardown
      } = Class.prototype;
      let hasUpdate = typeof update === 'function';
      let hasTeardown = typeof teardown === 'function';
      let owner = this.owner;
      let instance;
      let cache;

      if (hasUpdate) {
        cache = Ember._createCache(() => {
          if (instance === undefined) {
            instance = setupInstance(cache, Class, owner, args, hasTeardown);
          } else {
            instance.update();
          }

          return instance;
        });
      } else {
        cache = Ember._createCache(() => {
          if (instance !== undefined) {
            Ember.destroy(instance);
          }

          instance = setupInstance(cache, Class, owner, args, hasTeardown);
          return instance;
        });
      }

      return cache;
    }

    getValue(cache) {
      let instance = Ember._cacheGetValue(cache);

      return instance;
    }

    getDestroyable(cache) {
      return cache;
    }

    getDebugName(fn) {
      return fn.name || '(anonymous function)';
    }

  }

  function setupInstance(cache, Class, owner, args, hasTeardown) {
    let instance = new Class(owner, args);

    Ember._associateDestroyableChild(cache, instance);

    instance.setup();

    if (hasTeardown) {
      Ember._registerDestructor(instance, () => instance.teardown());
    }

    return instance;
  }

  Ember._setHelperManager(owner => new ResourceManager(owner), Resource);
});