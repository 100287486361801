define("ember-power-select-with-create/templates/components/power-select-with-create/suggested-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cLzCuqsc",
    "block": "[[[1,[33,0,[\"text\"]]],[1,\"\\n\"]],[],false,[\"option\"]]",
    "moduleName": "ember-power-select-with-create/templates/components/power-select-with-create/suggested-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});