define("ember-keyboard/mixins/activate-keyboard-on-insert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activateKeyboardWhenPresent: Ember.on('didInsertElement', function () {
      Ember.set(this, 'keyboardActivated', true);
    })
  });

  _exports.default = _default;
});