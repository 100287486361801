define("ember-animated-tools/helpers/rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rounded = rounded;
  _exports.default = void 0;

  function rounded([value]) {
    if (value != null) {
      return Math.round(value);
    }
  }

  var _default = Ember.Helper.helper(rounded);

  _exports.default = _default;
});