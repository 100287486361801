define("ember-modifier/-private/class/modifier-manager", ["exports", "ember-modifier/-private/class/modifier"], function (_exports, _modifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ClassBasedModifierManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.13'));

      this.owner = owner;
    }

    createModifier(factory, args) {
      let Modifier = factory.class;
      return new Modifier(this.owner, args);
    }

    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }

    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      Ember.set(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }

    destroyModifier(instance) {
      instance.willRemove();
      instance.element = null;

      if (instance[_modifier.DESTROYING]) {
        return;
      }

      let meta = Ember.meta(instance);
      instance[_modifier.DESTROYING] = true;

      if (true) {
        // call this on 3.20+ early because it is using the
        // @ember/destroyable API's `destroy` function (this will
        // ensure that any users _on_ 3.20 that have called
        // `registerDestructor` have their destructors called
        // eslint-disable-next-line ember/new-module-imports
        Ember.destroy(instance);
      } else {
        meta.setSourceDestroying();
      }

      Ember.run.schedule('actions', instance, instance.willDestroy);
      Ember.run.schedule('destroy', undefined, scheduleDestroy, instance, meta);
    }

  }

  function scheduleDestroy(modifier, meta) {
    if (modifier[_modifier.DESTROYED]) {
      return;
    }

    if (!true) {
      // in 3.20+ we call destroy _early_ (because it is actually
      // the @ember/destroyable's `destroy` API)
      // eslint-disable-next-line ember/new-module-imports
      Ember.destroy(modifier);
      meta.setSourceDestroyed();
    }

    modifier[_modifier.DESTROYED] = true;
  }

  var _default = ClassBasedModifierManager;
  _exports.default = _default;
});