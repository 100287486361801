define("ember-parachute/decorators/with-parachute", ["exports", "ember-parachute/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withParachute;

  function withParachute(desc) {
    return { ...desc,

      finisher(klass) {
        klass.reopen(new _queryParams.default().Mixin);
        return klass;
      }

    };
  }
});