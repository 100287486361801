define("ember-parachute/decorators/index", ["exports", "ember-parachute/decorators/query-param", "ember-parachute/decorators/with-parachute"], function (_exports, _queryParam, _withParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "queryParam", {
    enumerable: true,
    get: function () {
      return _queryParam.default;
    }
  });
  Object.defineProperty(_exports, "withParachute", {
    enumerable: true,
    get: function () {
      return _withParachute.default;
    }
  });
});