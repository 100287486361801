define('ember-window-mock/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reset = reset;
  exports.default = window;
  function reset() {
    (false && !(true) && Ember.assert('reset() is only available in tests', true));
  }
});