define("ember-table/components/ember-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YsM0LTXR",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[33,3],[33,4]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[33,3,[\"name\"]]],[1,\"\\n\\n  \"],[1,[28,[35,5],null,[[\"columnMeta\"],[[33,4]]]]],[1,\"\\n\\n  \"],[1,[28,[35,6],null,[[\"columnMeta\"],[[33,4]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"columnValue\",\"columnMeta\",\"ember-th/sort-indicator\",\"ember-th/resize-handle\"]]",
    "moduleName": "ember-table/components/ember-th/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});