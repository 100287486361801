define("ember-keyboard/utils/handle-key-event", ["exports", "ember-keyboard/utils/get-mouse-name", "ember-keyboard/utils/get-code", "ember-keyboard/utils/listener-name"], function (_exports, _getMouseName, _getCode, _listenerName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleKeyEventWithPropagation = handleKeyEventWithPropagation;
  _exports.handleKeyEventWithLaxPriorities = handleKeyEventWithLaxPriorities;

  function gatherKeys(event) {
    const key = (0, _getCode.default)(event);
    const mouseButton = (0, _getMouseName.default)(event.button);
    const primaryEvent = [];
    if (Ember.isPresent(key)) primaryEvent.push(key);
    if (Ember.isPresent(mouseButton)) primaryEvent.push(mouseButton);
    return ['alt', 'ctrl', 'meta', 'shift'].reduce((keys, keyName) => {
      if (event[`${keyName}Key`]) {
        keys.push(keyName);
      }

      return keys;
    }, primaryEvent);
  }

  function handleKeyEventWithPropagation(event, {
    firstResponders,
    normalResponders
  }) {
    const keys = gatherKeys(event);
    const listenerNames = [(0, _listenerName.default)(event.type, keys), (0, _listenerName.default)(event.type)];
    let isImmediatePropagationStopped = false;
    let isPropagationStopped = false;
    const ekEvent = {
      stopImmediatePropagation() {
        isImmediatePropagationStopped = true;
      },

      stopPropagation() {
        isPropagationStopped = true;
      }

    };

    for (const responder of firstResponders) {
      for (const listenerName of listenerNames) {
        responder.trigger(listenerName, event, ekEvent);
      }

      if (isImmediatePropagationStopped) {
        break;
      }
    }

    if (isPropagationStopped) {
      return;
    }

    isImmediatePropagationStopped = false;
    let previousPriorityLevel = Number.POSITIVE_INFINITY;

    for (const responder of normalResponders) {
      const currentPriorityLevel = Number(Ember.get(responder, 'keyboardPriority'));

      if (isImmediatePropagationStopped && currentPriorityLevel === previousPriorityLevel) {
        continue;
      }

      if (currentPriorityLevel < previousPriorityLevel) {
        if (isPropagationStopped) {
          return;
        }

        isImmediatePropagationStopped = false;
        previousPriorityLevel = currentPriorityLevel;
      }

      for (const listenerName of listenerNames) {
        responder.trigger(listenerName, event, ekEvent);
      }
    }
  }

  function handleKeyEventWithLaxPriorities(event, sortedResponders) {
    let currentPriorityLevel;
    let noFirstResponders = true;
    let isLax = true;
    const keys = gatherKeys(event);
    const listenerNames = [(0, _listenerName.default)(event.type)];
    if (keys.length > 0) listenerNames.unshift((0, _listenerName.default)(event.type, keys));
    sortedResponders.every(responder => {
      const keyboardFirstResponder = Ember.get(responder, 'keyboardFirstResponder');
      const keyboardPriority = Ember.get(responder, 'keyboardPriority');

      if (keyboardFirstResponder || noFirstResponders && keyboardPriority >= currentPriorityLevel || isLax) {
        if (!Ember.get(responder, 'keyboardLaxPriority')) {
          isLax = false;
        }

        if (keyboardFirstResponder) {
          if (!isLax) {
            noFirstResponders = false;
          }
        } else {
          currentPriorityLevel = keyboardPriority;
        }

        listenerNames.forEach(triggerName => {
          if (responder.has(triggerName)) {
            responder.trigger(triggerName, event);
          }
        });
        return true;
      } else {
        return false;
      }
    });
  }
});