define("ember-could-get-used-to-this/-private/functions", [], function () {
  "use strict";

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FunctionalHelperManager {
    constructor() {
      _defineProperty(this, "capabilities", Ember._helperManagerCapabilities('3.23', {
        hasValue: true
      }));
    }

    createHelper(fn, args) {
      return {
        fn,
        args
      };
    }

    getValue({
      fn,
      args
    }) {
      (false && !(Object.keys(args.named).length === 0) && Ember.assert(`Functional helpers cannot receive hash parameters. \`${this.getDebugName(fn)}\` received ${Object.keys(args.named)}`, Object.keys(args.named).length === 0));
      return fn(...args.positional);
    }

    getDebugName(fn) {
      return fn.name || '(anonymous function)';
    }

  }

  const FUNCTIONAL_HELPER_MANAGER = new FunctionalHelperManager();

  Ember._setHelperManager(() => FUNCTIONAL_HELPER_MANAGER, Function.prototype);
});